import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconNode = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <defs>
        <clipPath id="clipPath">
          <path
            id="Tracé_6423"
            data-name="Tracé 6423"
            d="M202.743,226.053l-7.911,4.566a.955.955,0,0,0-.478.827v9.139a.955.955,0,0,0,.478.827l7.912,4.57a.958.958,0,0,0,.956,0l7.911-4.57a.957.957,0,0,0,.477-.827v-9.139a.955.955,0,0,0-.479-.827l-7.91-4.566a.962.962,0,0,0-.958,0"
            transform="translate(-194.354 -225.925)"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient"
          x1="-0.847"
          y1="-1.034"
          x2="-0.838"
          y2="-1.034"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.3" stopColor="#3e863d" />
          <stop offset="0.5" stopColor="#55934f" />
          <stop offset="0.8" stopColor="#5aad45" />
        </linearGradient>
        <clipPath id="clipPath-2">
          <path
            id="Tracé_6425"
            data-name="Tracé 6425"
            d="M195.4,241.283a.957.957,0,0,0,.282.247l6.787,3.92,1.13.65a.959.959,0,0,0,.551.125.978.978,0,0,0,.188-.034l8.344-15.278a.945.945,0,0,0-.222-.177l-5.18-2.991-2.738-1.576a1,1,0,0,0-.248-.1Zm0,0"
            transform="translate(-195.398 -226.069)"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient-2"
          x1="-0.742"
          y1="-0.932"
          x2="-0.737"
          y2="-0.932"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.57" stopColor="#3e863d" />
          <stop offset="0.72" stopColor="#619857" />
          <stop offset="1" stopColor="#76ac64" />
        </linearGradient>
        <clipPath id="clipPath-3">
          <path
            id="Tracé_6427"
            data-name="Tracé 6427"
            d="M205.292,225.938a.965.965,0,0,0-.382.122l-7.889,4.553,8.507,15.494a.949.949,0,0,0,.34-.117l7.911-4.57a.959.959,0,0,0,.462-.653l-8.672-14.814a.993.993,0,0,0-.193-.019c-.026,0-.053,0-.079,0"
            transform="translate(-197.021 -225.934)"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient-3"
          x1="-2.148"
          y1="-2.103"
          x2="-2.138"
          y2="-2.103"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.16" stopColor="#6bbf47" />
          <stop offset="0.38" stopColor="#79b461" />
          <stop offset="0.47" stopColor="#75ac64" />
          <stop offset="0.7" stopColor="#659e5a" />
          <stop offset="0.9" stopColor="#3e863d" />
        </linearGradient>
      </defs>
      <g id="Node.js_logo" transform="translate(0)">
        <path
          id="Tracé_6418"
          data-name="Tracé 6418"
          d="M179.357,182.379a1.579,1.579,0,0,1-.787-.21l-2.5-1.483c-.374-.209-.192-.283-.068-.326a5.057,5.057,0,0,0,1.132-.516.19.19,0,0,1,.186.013L179.24,181a.254.254,0,0,0,.232,0l7.5-4.33a.237.237,0,0,0,.115-.2v-8.657a.241.241,0,0,0-.116-.207l-7.5-4.327a.231.231,0,0,0-.231,0l-7.5,4.327a.239.239,0,0,0-.119.2v8.657a.231.231,0,0,0,.116.2l2.054,1.187c1.115.558,1.8-.1,1.8-.76v-8.544a.214.214,0,0,1,.218-.216h.95a.215.215,0,0,1,.216.216v8.548a2.08,2.08,0,0,1-2.221,2.342,3.216,3.216,0,0,1-1.729-.469l-1.968-1.134a1.586,1.586,0,0,1-.787-1.369v-8.657a1.576,1.576,0,0,1,.787-1.367l7.51-4.332a1.645,1.645,0,0,1,1.577,0l7.5,4.334a1.583,1.583,0,0,1,.787,1.367v8.657a1.587,1.587,0,0,1-.787,1.367l-7.5,4.332a1.577,1.577,0,0,1-.791.21"
          transform="translate(-138.298 -131.503)"
          fill="#689f63"
        />
        <path
          id="Tracé_6419"
          data-name="Tracé 6419"
          d="M213.813,193.677c-3.282,0-3.971-1.507-3.971-2.771a.216.216,0,0,1,.217-.216h.969a.217.217,0,0,1,.214.184c.146.987.582,1.485,2.567,1.485,1.581,0,2.253-.357,2.253-1.2,0-.483-.192-.841-2.648-1.082-2.054-.2-3.324-.655-3.324-2.3,0-1.514,1.277-2.415,3.416-2.415,2.4,0,3.592.834,3.743,2.625a.219.219,0,0,1-.058.167.223.223,0,0,1-.16.069h-.975a.215.215,0,0,1-.21-.169c-.235-1.038-.8-1.371-2.344-1.371-1.726,0-1.927.6-1.927,1.052,0,.546.237.706,2.565,1.014,2.306.306,3.4.738,3.4,2.359s-1.363,2.571-3.741,2.571m9.138-9.181h.252a.219.219,0,0,0,.246-.229c0-.222-.152-.222-.237-.222h-.259Zm-.306-.71h.558c.192,0,.567,0,.567.428a.373.373,0,0,1-.306.4c.223.015.238.161.269.368a1.583,1.583,0,0,0,.085.428h-.344c-.009-.075-.062-.488-.062-.511-.023-.092-.054-.137-.169-.137h-.284v.65h-.314Zm-.67.807a1.212,1.212,0,1,0,1.207-1.209,1.214,1.214,0,0,0-1.207,1.209m2.659.006a1.446,1.446,0,1,1-1.446-1.446,1.45,1.45,0,0,1,1.446,1.446"
          transform="translate(-170.437 -148.766)"
          fill="#689f63"
        />
        <path
          id="Tracé_6420"
          data-name="Tracé 6420"
          d="M17.827,17a.961.961,0,0,0-.48-.835L9.393,11.593a.929.929,0,0,0-.437-.126H8.874a.945.945,0,0,0-.439.126L.481,16.169A.968.968,0,0,0,0,17L.017,29.331a.473.473,0,0,0,.238.415.454.454,0,0,0,.477,0l4.728-2.708a.97.97,0,0,0,.481-.834V20.446a.958.958,0,0,1,.48-.832l2.013-1.159a.955.955,0,0,1,.481-.129.938.938,0,0,1,.478.129l2.012,1.159a.958.958,0,0,1,.481.832V26.2a.973.973,0,0,0,.482.834l4.725,2.706a.47.47,0,0,0,.481,0,.482.482,0,0,0,.238-.415ZM55.36,23.422a.239.239,0,0,1-.12.208L52.508,25.2a.241.241,0,0,1-.24,0l-2.732-1.575a.238.238,0,0,1-.12-.208V20.268a.242.242,0,0,1,.118-.208l2.73-1.577a.243.243,0,0,1,.242,0l2.732,1.577a.239.239,0,0,1,.12.208ZM56.1.061a.48.48,0,0,0-.715.42V12.687a.336.336,0,0,1-.5.291l-1.992-1.148a.959.959,0,0,0-.96,0L43.97,16.422a.959.959,0,0,0-.481.83v9.186a.962.962,0,0,0,.481.832l7.956,4.6a.965.965,0,0,0,.961,0l7.956-4.6a.963.963,0,0,0,.481-.832V3.542a.963.963,0,0,0-.494-.839Zm26.5,20.124a.961.961,0,0,0,.478-.83V17.128a.961.961,0,0,0-.478-.831l-7.906-4.589a.96.96,0,0,0-.963,0L65.768,16.3a.959.959,0,0,0-.481.832v9.183a.964.964,0,0,0,.484.836l7.9,4.5a.958.958,0,0,0,.943.006L79.4,29a.48.48,0,0,0,0-.836l-8-4.593a.477.477,0,0,1-.242-.415V20.278a.477.477,0,0,1,.24-.416l2.49-1.437a.475.475,0,0,1,.479,0l2.492,1.437a.479.479,0,0,1,.24.415v2.265a.479.479,0,0,0,.721.415Zm0,0"
          transform="translate(0 0)"
          fill="#fff"
          fillRule="evenodd"
        />
        <path
          id="Tracé_6421"
          data-name="Tracé 6421"
          d="M387.533,105.092a.182.182,0,0,1,.184,0l1.527.881a.184.184,0,0,1,.092.16V107.9a.184.184,0,0,1-.092.16l-1.527.881a.182.182,0,0,1-.184,0l-1.526-.881a.184.184,0,0,1-.094-.16v-1.763a.182.182,0,0,1,.092-.16Zm0,0"
          transform="translate(-313.445 -85.337)"
          fill="#689f63"
          fillRule="evenodd"
        />
        <g
          id="Groupe_2954"
          data-name="Groupe 2954"
          transform="translate(21.792 11.625)"
          clipPath="url(#clipPath)"
        >
          <path
            id="Tracé_6422"
            data-name="Tracé 6422"
            d="M185.5,199.53,163.208,188.6l-11.43,23.318,22.292,10.928Zm0,0"
            transform="translate(-159.773 -195.611)"
            fill="url(#linear-gradient)"
          />
        </g>
        <g
          id="Groupe_2955"
          data-name="Groupe 2955"
          transform="translate(21.988 11.652)"
          clipPath="url(#clipPath-2)"
        >
          <path
            id="Tracé_6424"
            data-name="Tracé 6424"
            d="M144.07,197.481l15.741,21.3,20.818-15.381-15.742-21.3Zm0,0"
            transform="translate(-153.709 -190.358)"
            fill="url(#linear-gradient-2)"
          />
        </g>
        <g
          id="Groupe_2956"
          data-name="Groupe 2956"
          transform="translate(22.293 11.627)"
          clipPath="url(#clipPath-3)"
        >
          <path
            id="Tracé_6426"
            data-name="Tracé 6426"
            d="M197.02,225.934v20.174h17.217V225.934Zm0,0"
            transform="translate(-197.02 -225.934)"
            fill="url(#linear-gradient-3)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconNode;
