import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  cardOfferSection: {
    position: 'relative',
    '& .technologiesIconAws': {
      maxWidth: 90,
      width: 90,
    },
    '& .technologiesIconDigitalOcean': {
      maxWidth: 100,
    },
    '& .technologiesIconHosteur': {
      maxWidth: 150,
    },
    '& .technologiesIconGoogleCloud': {
      maxWidth: 225,
      width: 225,
    },

    '& .technologiesIconAzure': {
      maxWidth: 150,
    },
    '& .MuiList-root': {
      justifyContent: 'space-around',
    },
  },
}));
