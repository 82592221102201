import React, { useEffect, useState } from 'react';
import styles from './style';
import { Box } from '@material-ui/core';
import IconAws from '../../Common/Icons/IconAws';
import IconNode from '../../Common/Icons/IconNode';
import IconReactJS from '../../Common/Icons/IconReact';
import ScrollTo from '../../Common/ScrollTo';
import ContactForm from '../../Common/ContactForm';
import Techonologies from '../../Common/Technologies';
import BlocHeaderTemoignage from '../../Common/BlocHeaderTemoignage';
import BlocGallery from '../../Common/BlocGalleryTemoignage';
import MotClient from '../../Common/BlocMotsClient';
import img1 from '../../../images/viewpay/mockup_laptop_viewpay.png';
import gallery1 from '../../../images/viewpay/mockup_laptop_viewpay.png';

const TechnologiesItem2 = [
  {
    id: 1,
    icon: <IconAws width="85.223" height="50.96" viewBox="0 0 85.223 50.96" />,
    title: 'IconAws',
  },
  {
    id: 2,
    icon: <IconNode width="177.666" height="65" viewBox="0 0 177.666 50.96" />,
    title: 'IconNode',
  },
  {
    id: 3,
    icon: <IconReactJS width="190" height="50.961" viewBox="0 0 190 50.961" />,
    title: 'IconReactJS',
  },
];

const GalleryItems = [
  {
    id: 1,
    images: gallery1,
  },
];
const PageViewpay = () => {
  const classes = styles();
  const [showScrollTo, setShowScrollTo] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setShowScrollTo(offset > 1000);
  };
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Box id="viewpayPage" className={classes.cardOfferSection}>
      {showScrollTo ? (
        <ScrollTo element="/awspartner/viewpay/#viewpayPage" />
      ) : null}

      <BlocHeaderTemoignage
        title="Viewpay"
        subTitle="Courte description du projet"
        description="Viewpay étant une entreprise qui offre des solutions technologiques pour les médias et la publicité en ligne, nous avons fourni à cette dernière une application permettant d'avoir un reporting sur les données générées par la plateforme Viewpay à travers des dashboards (administrateurs, annonceurs et éditeurs). Pour cette prestation, nous avons utilisé des services de AWS tels que CloudWatch sy EC2."
        image={img1}
      />
      <BlocGallery
        title="<span>Quelques </span>images"
        description="Découvrez les actus de HaiRun Technology, des astuces et pleins d'autres items autour de la technologie et du digital."
        items={GalleryItems}
      />
      <Techonologies
        bgcolor="#333333"
        title="Les Technologies utilisées <br/> <span>pour la réalisation du projet</span>"
        description="Les Logiciels et application web que nous utilisons dans le projet"
        items={TechnologiesItem2}
        minWidthItem="120px"
        marginTopItem="24px"
        marginBottomItem="24px"
      />
      <MotClient
        title="<span>Un petit mot </span>du client"
        description="Ayant confié des missions d'assistances techniques en architecture cloud AWS auprès de HaiRun Technology, nous en sommes extrêmement satisfaits. Ils nous ont prouvé leurs capacités à bien mener cette prestation, de manière très efficace. Nous les recommandons vivement pour ce genre de mission !"
        name="Marc Leprat"
        poste="Co-founder & CEO"
      />
       {!domaine_ext ?
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.com"
          formTitle="Contactez-<span>nous</span>"
        />
        :
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.fr"
          formTitle="Contactez-<span>nous</span>"
        />}
    </Box>
  );
};

export default PageViewpay;
